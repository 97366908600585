import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/app.api';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {

  urlService(){
    return 'login'
  }

  getUsers(page: any): Observable<any> {
    return this.http.get(`${API}/${this.urlService()}`, {params: page})
  }

  getDesabilitar(id:Number, body): Observable<any>{
    return this.http.put(`${API}/${this.urlService()}/update-status/${id}`, body)
  }

}
